import React, { Component } from "react";
import EthereumContext from "./ethereum";
import * as Web3 from "web3";
import BigNumber from "bignumber.js";
import { OrderSide } from "opensea-js/lib/types";
import { OpenSeaPort, Network } from "opensea-js";
import Portis from "@portis/web3";
import jQuery from "jquery";
import Swal from "sweetalert2";
import eth721 from "./utility/abi721";
import eth1155 from "./utility/abi1155";
import { NFTStorage, File } from "nft.storage/dist/bundle.esm.min.js";

class EthereumProvider extends Component {
  contract = {
    goerli: {
      ERC721: {
        abi: eth721,
        contract: "0x99BE31Bcf1dc9d45215394485a696635880fb401",
      },
      ERC1155: {
        abi: eth1155,
        contract: "0x9cb1E105cA57AEe9756C22781553d61485737666",
      },
    },
    main: {
      ERC721: {
        abi: eth721,
        contract: "0x021530dAC7Ae7AF3a9748b63b09E1134fc479a81",
      },
      ERC1155: { abi: eth1155, contract: "" },
    },
  };

  urlFabric = "https://testnet.alfwallet.com/fabric.php";
  NFT_STORAGE_KEY =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDFDN2MwQzlhOTcxMzU4MDQ0NjNhNjU1OGYwM0Y2OUYwMEIxOUE3ZmUiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY3OTA2NjI0NDM0MywibmFtZSI6IkFMRiJ9.d9tjqGXt4jX27SpYUC4JjShaIvcUzn8KePTNZespTro";

  state = {
    seaport: () => {},
    web3: () => {},
    accountAddress: "",
    ethBalance: 0.0,
    apiUrl: "",
    txUrl: "",
    orderData: [],
    ownTokens: [],
    networkId: 0,
    imageName: "",
    fileToken: null,
    ipfsGateway: "https://ipfs.infura.io/ipfs/",
    ipfsImageHash: "",
    ipfsImageUrl: "",
    ipfsMetadataHash: "",
    ipfsMetadataUrl: "",
    nameToken: "",
    descriptionToken: "",
    externalUrl: "",
    typeToken: "singular",
    quantityToken: 1,
    metadataBuffer: "",
    fields: [{ attrSelect: "", attrKey: "", attrValue: "" }],
  };

  nftstorage = new NFTStorage({ token: this.NFT_STORAGE_KEY });

  ethEnabled = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      return true;
    }
    return false;
  };

  defineNetwork = async () => {
    let web3 = this.state.web3;
    /* Network Id
			1: Mainnet
			5: Goerli
		*/
    let networkId = await web3.eth.net.getId();
    let apiUrl, txUrl;
    switch (networkId) {
      case 1:
        apiUrl = "https://api.opensea.io";
        txUrl = "https://etherscan.io/tx/";
        break;
      case 5:
        apiUrl = "https://testnets-api.opensea.io";
        txUrl = "https://goerli.etherscan.io/tx/";
        break;
      default:
        break;
    }

    this.setState({
      ...this.state,
      networkId: networkId,
      apiUrl: apiUrl,
      txUrl: txUrl,
    });
  };

  initialize = async () => {
    let web3;
    if (await this.ethEnabled()) {
      web3 = window.web3;
    } else {
      let network;
      switch (this.state.networkId) {
        case 1:
          network = "mainnet";
          break;
        case 5:
          network = "goerli";
          break;
        default:
          break;
      }

      const portis = new Portis(
        "26acea99-b38d-4eab-accf-1af4ad098733",
        network
      );
      web3 = new Web3(portis.provider);
    }

    const ethereum = web3.currentProvider;
    const accounts = await ethereum.enable();
    const account = accounts[0];

    const seaport = new OpenSeaPort(web3.currentProvider, {
      networkName: Network.Goerli,
    });

    this.setState({
      ...this.state,
      web3: web3,
      seaport: seaport,
      accountAddress: account,
    });

    await this.defineNetwork();
    await this.getBalance();
  };

  getBalance = async () => {
    let web3 = this.state.web3;
    let account = this.state.accountAddress;
    let self = this;

    web3.eth.getBalance(account, function (err, result) {
      if (err) {
        console.log(err);
      } else {
        self.setState({
          ...self.state,
          ethBalance: web3.utils.fromWei(result, "ether"),
        });
      }
    });
  };

  getOrders = async () => {
    await this.initialize();

    let seaport = this.state.seaport;

    // eslint-disable-next-line no-unused-vars
    const { orders, count } = await seaport.api.getOrders({
      side: OrderSide.Sell,
      limit: 12,
    });

    var ordersWithImages = orders.filter(function (order) {
      return order.takerAssetBundle
        ? order.takerAssetBundle.assets[0].imageUrl
        : false;
    });

    ordersWithImages.forEach((order) => {
      this.setState((prevState) => ({
        orderData: [...prevState.orderData, order],
      }));
    });

    (function ($) {
      $(".load-more .item").slice(0, 4).show();
    })(jQuery);
  };

  getPortfolio = async () => {
    await this.initialize();

    try {
      const response = await fetch(
        this.state.apiUrl +
          "/api/v1/assets?owner=" +
          this.state.accountAddress +
          "&limit=50&order_direction=desc"
      );
      if (response.status === 200) {
        console.log("Machine successfully found.");
        const result = await response.json();
        this.setState({
          ...this.state,
          ownTokens: result.assets,
        });

        (function ($) {
          $(".load-more .item").slice(0, 4).show();
        })(jQuery);
      } else {
        console.log("not a 200");
      }
    } catch (err) {
      console.log(err);
    }
  };

  buyToken = async (order) => {
    let seaport = this.state.seaport;
    const account = this.state.accountAddress;
    // eslint-disable-next-line no-unused-vars
    const transactionHash = await seaport.fulfillOrder({
      order,
      accountAddress: account,
    });
  };

  sellToken = async (token) => {
    const inputValue = 0.001;
    const inputStep = 0.001;

    Swal.fire({
      title: "Set a price in Gwei",
      input: "number",
      customClass: {
        input: "input-token",
        popup: "card",
      },
      inputValue,
      inputAttributes: {
        min: 0,
        max: 1000,
        step: inputStep,
      },
    }).then(async (result) => {
      if (result.value) {
        // 30 Days of expiration
        const expirationTime = Math.round(
          Date.now() / 1000 + 60 * 60 * 24 * 30
        );
        let seaport = this.state.seaport;
        let tokenId = token.token_id;
        let tokenAddress = token.asset_contract.address;
        let accountAddress = this.state.accountAddress;
        // eslint-disable-next-line no-unused-vars
        const listing = await seaport
          .createSellOrder({
            asset: {
              tokenId,
              tokenAddress,
              schemaName: token.asset_contract.schema_name,
            },
            accountAddress,
            startAmount: result.value,
            expirationTime,
          })
          .then((result) => {
            if (result) {
              Swal.fire({
                title: "Success!",
                customClass: {
                  input: "input-token",
                  popup: "card",
                },
                html: "Listing was create succesfully!",
                icon: "success",
              });
            } else {
              Swal.fire({
                title: "Something failed!",
                customClass: {
                  input: "input-token",
                  popup: "card",
                },
                icon: "error",
              });
            }
          });
      }
    });
  };

  sendToken = async (token) => {
    Swal.fire({
      title: "Send to address",
      input: "text",
      customClass: {
        input: "input-token",
        popup: "card",
      },
    }).then(async (result) => {
      if (result.value) {
        let seaport = this.state.seaport;
        let tokenId = token.token_id;
        let tokenAddress = token.asset_contract.address;
        let accountAddress = this.state.accountAddress;
        let toAddress = result.value;
        // eslint-disable-next-line no-unused-vars
        const transactionHash = await seaport
          .transfer({
            asset: {
              tokenId,
              tokenAddress,
            },
            fromAddress: accountAddress, // Must own the asset
            toAddress,
          })
          .then((result) => {
            if (result) {
              Swal.fire({
                title: "Success!",
                customClass: {
                  input: "input-token",
                  popup: "card",
                },
                html:
                  'Send was done succesfully!<p><a href="' +
                  this.state.txUrl +
                  result +
                  '" target="_blank" rel="noopener noreferrer" >View on Etherscan</a></p>',
                icon: "success",
              });
            } else {
              Swal.fire({
                title: "Something failed!",
                customClass: {
                  input: "input-token",
                  popup: "card",
                },
                icon: "error",
              });
            }
          });
      }
    });
  };

  readablePrice = (price) => {
    let num = new BigNumber(price);
    let denom = new BigNumber(10).pow(18);
    let ans = num.dividedBy(denom).toNumber();
    return ans;
  };

  captureFile = (input) => {
    input.stopPropagation();
    input.preventDefault();
    const file = input.target.files[0];

    this.setState({
      ...this.state,
      imageName: file.name,
      fileToken: file,
    });

    Swal.fire({
      title: "Loading",
      backdrop: false,
      customClass: {
        input: "input-token",
        popup: "card",
      },
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let reader = new window.FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = async () => {
      var blob = file.slice(0, file.size, file.type);
      var ext_file = file.name.split(".").pop();
      var temp_name = file.name.split(".")[0];
      let name = this.state.nameToken ? this.state.nameToken : temp_name;

      let newFile = new File([blob], `${name}.${ext_file}`, {
        type: file.type,
      });
      const cid = await this.nftstorage.storeBlob(newFile);

      this.setState({
        ...this.state,
        ipfsImageHash: cid,
        ipfsImageUrl: `https://${cid}.ipfs.dweb.link`,
      });
      Swal.close();
    };
  };

  handleChange = (e) => {
    let variable = e.target.name;
    let variableValue = e.target.value;
    let fields = [...this.state.fields];

    if (variable === "typeToken") {
      variableValue = e.target.id;
    }

    if (
      variable.includes("value") ||
      variable.includes("key") ||
      variable.includes("select")
    ) {
      let index = variable.split("-")[1];
      let element;
      switch (variable.split("-")[0]) {
        case "value":
          element = "attrValue";
          break;
        case "key":
          element = "attrKey";
          break;
        case "select":
          element = "attrSelect";
          break;
        default:
          break;
      }

      fields[index][element] = variableValue;
      this.setState({ ...this.state, fields });
    } else {
      this.setState({
        ...this.state,
        [variable]: variableValue,
      });
    }
  };

  addField = (e) => {
    this.setState((prevState) => ({
      fields: [
        ...prevState.fields,
        { attrSelect: "", attrKey: "", attrValue: "" },
      ],
    }));
  };

  removeField = (e) => {
    const fields = this.state.fields;
    fields.pop();
    if (fields.length > 0) {
      this.setState({ ...this.state, fields });
    }
  };

  buildJson = async () => {
    let jsonToken = {};
    jsonToken["name"] = this.state.nameToken;
    jsonToken["description"] = this.state.descriptionToken;
    jsonToken["external_url"] = this.state.externalUrl;
    jsonToken["image"] = this.state.ipfsImageUrl;
    jsonToken["image_url"] = this.state.ipfsImageUrl;
    jsonToken["home_url"] = this.state.externalUrl;
    jsonToken["attributes"] = [];
    jsonToken["properties"] = [];

    this.state.fields.forEach(function (attr, index) {
      if (attr.attrSelect.value === "text") {
        jsonToken["attributes"][index] = {
          trait_type: attr.attrKey,
          value: attr.attrValue,
        };
      } else {
        jsonToken["attributes"][index] = {
          display_type: attr.attrSelect.value,
          trait_type: attr.attrKey,
          value: attr.attrValue,
        };
      }
      jsonToken["properties"][index] = {
        key: attr.attrKey,
        value: attr.attrValue,
        type: "string",
      };
    });

    this.setState({
      ...this.state,
      metadataBuffer: JSON.stringify(jsonToken, null, 2),
    });
  };

  addJson = (tokenId, txUrl) => {
    let metadata = JSON.parse(this.state.metadataBuffer);
    metadata["id"] = tokenId;
    metadata["txUrl"] = txUrl;

    let network;
    switch (this.state.networkId) {
      case 1:
        network = "mainnet";
        break;
      case 4:
        network = "rinkeby";
        break;
      default:
        break;
    }

    metadata["network"] = network;

    metadata = JSON.stringify(metadata, null, 2);

    console.log(metadata);

    fetch(this.urlFabric, {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: metadata,
    });

    Swal.fire({
      title: "Successful Metadata Creation!",
      customClass: {
        input: "input-token",
        popup: "card",
      },
    });
  };

  mintToken = async () => {
    try {
      // bring in user's metamask account address
      const account = this.state.accountAddress;
      const web3 = this.state.web3;
      let abi;
      let contractAddress;
      let self = this;

      switch (this.state.networkId) {
        case 1:
          switch (this.state.typeToken) {
            case "singular":
              abi = this.contract["main"]["ERC721"]["abi"];
              contractAddress = this.contract["main"]["ERC721"]["contract"];
              break;
            case "multiple":
              abi = this.contract["main"]["ERC1155"]["abi"];
              contractAddress = this.contract["main"]["ERC1155"]["contract"];
              break;
            default:
              break;
          }
          break;
        case 5:
          switch (this.state.typeToken) {
            case "singular":
              abi = this.contract["goerli"]["ERC721"]["abi"];
              contractAddress = this.contract["goerli"]["ERC721"]["contract"];
              break;
            case "multiple":
              abi = this.contract["goerli"]["ERC1155"]["abi"];
              contractAddress = this.contract["goerli"]["ERC1155"]["contract"];
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }

      const tokenInstance = new web3.eth.Contract(abi, contractAddress);

      console.log(this.state.networkId);

      // see, this https://web3js.readthedocs.io/en/1.0/web3-eth-contract.html#methods-mymethod-send
      switch (this.state.typeToken) {
        case "singular":
          if (this.state.networkId === 1) {
            await tokenInstance.methods
              .createArt(this.state.accountAddress, this.state.ipfsMetadataUrl)
              .send(
                {
                  from: account,
                },
                (error, txHash) => {
                  console.log("txHash: " + txHash + ", error: " + error);

                  if (txHash !== undefined) {
                    Swal.fire({
                      title: "The token was created successfully!",
                      html:
                        '<a href="' +
                        this.state.txUrl +
                        txHash +
                        '" target="_blank" rel="noopener noreferrer">TX: ' +
                        txHash +
                        "</a>",
                      customClass: {
                        input: "input-token",
                        popup: "card",
                      },
                      icon: "success",
                    });
                  }
                }
              );
          } else {
            await tokenInstance.methods
              .createArt(this.state.accountAddress, this.state.ipfsMetadataUrl)
              .send(
                {
                  from: account,
                },
                (error, txHash) => {
                  console.log("txHash: " + txHash + ", error: " + error);

                  if (txHash !== undefined) {
                    Swal.fire({
                      title: "The token was created successfully!",
                      html:
                        '<a href="' +
                        this.state.txUrl +
                        txHash +
                        '" target="_blank" rel="noopener noreferrer">TX: ' +
                        txHash +
                        "</a>",
                      customClass: {
                        input: "input-token",
                        popup: "card",
                      },
                      icon: "success",
                    });
                  }
                }
              );
          }

          break;
        case "multiple":
          await tokenInstance.methods
            .createToken(this.state.accountAddress,Math.floor(Math.random() * 125000), this.state.quantityToken)
            .send(
              {
                from: account,
              },
              (error, txHash) => {
                console.log("txHash: " + txHash + ", error: " + error);

                if (txHash !== undefined) {
                  Swal.fire({
                    title: "The token was created successfully!",
                    html:
                      '<a href="' +
                      this.state.txUrl +
                      txHash +
                      '" target="_blank" rel="noopener noreferrer">TX: ' +
                      txHash +
                      "</a>",
                    customClass: {
                      input: "input-token",
                      popup: "card",
                    },
                    icon: "success",
                  });
                }

                Swal.fire({
                  title: "Loading",
                  backdrop: false,
                  customClass: {
                    input: "input-token",
                    popup: "card",
                  },
                  didOpen: () => {
                    Swal.showLoading();
                  },
                });
              }
            )
            .then(function (receipt) {
              Swal.close();
              let txUrl =
                self.state.txUrl +
                receipt.events.TransferSingle.transactionHash;
              let tokenId = receipt.events.TransferSingle.returnValues.id;
              self.addJson(tokenId, txUrl);
            });
          break;
        default:
          break;
      }
    } catch (e) {
      console.log("Error: " + e);
    }
  };

  createToken = async () => {
    await this.initialize().then(async () => {
      await this.buildJson().then(async () => {
        const str = this.state.metadataBuffer;
        const bytes = new TextEncoder().encode(str);
        const blobJson = new Blob([bytes], {
          type: "application/json;charset=utf-8",
        });

        let cidMetadata = await this.nftstorage.storeBlob(blobJson);

        console.log(cidMetadata);

        this.setState(
          {
            ...this.state,
            ipfsMetadataHash: cidMetadata,
            ipfsMetadataUrl: `https://${cidMetadata}.ipfs.dweb.link`,
          },
          async () => {
            await this.mintToken();
          }
        );
      });
    });
  };

  render() {
    return (
      <EthereumContext.Provider
        value={{
          accountAddress: this.state.accountAddress,
          ethBalance: this.state.ethBalance,
          orderData: this.state.orderData,
          ownTokens: this.state.ownTokens,
          ipfsImageUrl: this.state.ipfsImageUrl,
          ipfsMetadataUrl: this.state.ipfsMetadataUrl,
          imageName: this.state.imageName,
          nameToken: this.state.nameToken,
          descriptionToken: this.state.descriptionToken,
          externalUrl: this.state.externalUrl,
          typeToken: this.state.typeToken,
          quantityToken: this.state.quantityToken,
          fields: this.state.fields,
          getOrders: () => this.getOrders(),
          getPortfolio: () => this.getPortfolio(),
          readablePrice: (price) => this.readablePrice(price),
          buyToken: (order) => this.buyToken(order),
          sellToken: (token) => this.sellToken(token),
          sendToken: (token) => this.sendToken(token),
          captureFile: (e) => this.captureFile(e),
          handleChange: (e) => this.handleChange(e),
          addField: (e) => this.addField(e),
          removeField: (e, index) => this.removeField(e, index),
          createToken: () => this.createToken(),
        }}
      >
        {this.props.children}
      </EthereumContext.Provider>
    );
  }
}

export default EthereumProvider;
