import React, { useContext, useEffect } from 'react';
import EthereumContext from '../../context/ethereum';

const ExploreFour = () => {
    const initData = {
        pre_heading: "Explore",
        heading: "Your Digital Assets",
        btnText: "Load More",
        sellBtn: 'Sell Token',
      	sendBtn: 'Send Token'
    }

    const eth = useContext(EthereumContext);

    useEffect(() => {
        if (eth.ownTokens.length === 0) {
            eth.getPortfolio();
        }
        // eslint-disable-next-line
    }, [eth.ownTokens]);

    return(
        <EthereumContext.Consumer>
            {context => (
                <section className="explore-area load-more">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-8 col-lg-7">
                                {/* Intro */}
                                <div className="intro text-center">
                                    <span>{initData.pre_heading}</span>
                                    <h3 className="mt-3 mb-0">{initData.heading}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row items">
                            {context.ownTokens.map((item, idx) => {
                                return (
                                    <div key={`exf_${idx}`} className="col-12 col-sm-6 col-lg-3 item">
                                        <div className="card">
                                            <div className="image-over">
                                                <a href={item.permalink}>
                                                    <img className="card-img-top" src={item.image_preview_url} alt="" />
                                                </a>
                                            </div>
                                            {/* Card Caption */}
                                            <div className="card-caption col-12 p-0">
                                                {/* Card Body */}
                                                <div className="card-body">
                                                    <a href={item.permalink}>
                                                        {item.name ?
                                                            <h5 className="mb-0">{item.name}</h5> :
                                                            <h5 className="mb-0">Untitled</h5>}
                                                    </a>
                                                    <div className="seller align-items-center my-3">
                                                        <span>Schema Type</span>
                                                        {console.log(item)}
                                                        <p className="ml-2 mb-0">{item.asset_contract.schema_name}</p>
                                                    </div>
                                                    <button 
                                                        className="btn btn-bordered-white btn-smaller mt-3" 
                                                        onClick={() => context.sellToken(item)}
                                                    >
                                                        <i className="icon-diamond mr-2" />{initData.sellBtn}
                                                    </button>
                                                    <button 
                                                        className="btn btn-bordered-white btn-smaller mt-3" 
                                                        onClick={() => context.sendToken(item)}
                                                    >
                                                        <i className="icon-arrow-right mr-2" />{initData.sendBtn}
                                                    </button>	
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <button id="load-btn" className="btn btn-bordered-white mt-5">{initData.btnText}</button>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </EthereumContext.Consumer>
    )
}



export default ExploreFour;
